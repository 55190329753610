import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import CloseIcon from "../icons/CloseIcon";
import SidebarItem from "./SidebarItem";
import SIDEBAR_ITEMS from "./sidebarItems";
import "./sidebar.css";
import LogoutButton from "../../widgets/LogoutButton";
import { UserContext } from "../../context/User";
import ChevronDoubleRight from "../icons/ChevronDoubleRight";

const Sidebar = () => {
    const sidebarItemsRef = useRef(null);
    const sidebarRef = useRef(null);
    const menuInnerShadowRef = useRef(null);

    const user = useContext(UserContext);

    useEffect(() => {
        const handleScroll = () => {
            if (sidebarItemsRef.current.scrollTop > 0) {
                menuInnerShadowRef.current.classList.add("active");
            } else {
                menuInnerShadowRef.current.classList.remove("active");
            }
        };

        sidebarItemsRef.current.addEventListener("scroll", handleScroll);

        return () => {
            sidebarItemsRef?.current?.removeEventListener(
                "scroll",
                handleScroll
            );
        };
    }, []);

    const closeSidebar = () => {
        document.body.classList.remove("mobile-sidebar-open");
    };

    const toggleMenuCollapse = () => {
        const isNowMenuCollapse =
            !document.body.classList.contains("menu-collapsed");

        localStorage.setItem("is_menu_collapse", String(isNowMenuCollapse));
        document.body.classList.toggle("menu-collapsed");

        if (isNowMenuCollapse) {
            sidebarRef.current.classList.add("hide");
            setTimeout(() => {
                sidebarRef.current.classList.remove("hide");
            }, 200);
        }
    };

    const handleMouseEnter = () => {
        document.body.classList.add("menu-collapsed-hover");
    };

    const handleMouseLeave = () => {
        document.body.classList.remove("menu-collapsed-hover");
        if (document.body.classList.contains("menu-collapsed")) {
            setTimeout(() => {
                sidebarItemsRef.current.scrollTop = 0;
            }, 10);
        }
    };

    const closeMobileMenu = () => {
        if (window.innerWidth < 1200) {
            sidebarItemsRef.current.scrollTop = 0;
            document.body.classList.remove("mobile-sidebar-open");
            document.body.classList.remove("menu-collapsed-hover");
            document.body.scrollTop = 0;
        }
    };

    return (
        <>
            <div />
            <div
                className="sidebar"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                ref={sidebarRef}
            >
                <div className="sidebar-header">
                    <div className="sidebar-header-content">
                        <Link to="/" className="mainmenu-logo">
                            <div className="" />
                        </Link>
                        <button
                            className="menu-collapse-btn"
                            onClick={toggleMenuCollapse}
                        >
                            {/* <div /> */}
                            <ChevronDoubleRight />
                        </button>
                        <button
                            className="hide-sidebar"
                            type="button"
                            onClick={closeSidebar}
                        >
                            <CloseIcon />
                        </button>
                        <div className="logo-backdrop" />
                    </div>
                    <div className="sidebar-user-info">
                        <span>{user.clientName}, </span>
                        <span>{user.login}</span>
                    </div>
                    <div
                        className="menu-inner-shadow"
                        ref={menuInnerShadowRef}
                    />
                </div>

                <div
                    className="sidebar-items perfect-scrollbar"
                    ref={sidebarItemsRef}
                >
                    <div className="sidebar-items-up-block">
                        {SIDEBAR_ITEMS.map((item) => (
                            <SidebarItem
                                key={item.title}
                                title={item.title}
                                children={item.children}
                                path={item.path}
                                icon={item.icon}
                                accessLevel={item.accessLevel}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </div>
                    <LogoutButton />
                </div>
            </div>
            <div className="sidebar-backdrop" onClick={closeSidebar}></div>
        </>
    );
};

export default Sidebar;
