const FacebookSecondIcon = () => (
    <svg
        className="facebook-second-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle
            cx="12"
            cy="12"
            r="10.375"
            stroke="#A7AFDD"
            strokeWidth="1.25"
        />
        <path
            d="M12.9422 13.1391V19H10.2427V13.1391H8V10.7626H10.2427V9.89793C10.2427 6.68787 11.5873 5 14.4322 5C15.3043 5 15.5224 5.13979 16 5.2537V7.60429C15.4653 7.51109 15.3147 7.45932 14.7592 7.45932C14.0999 7.45932 13.7469 7.64571 13.425 8.01331C13.1032 8.38092 12.9422 9.01775 12.9422 9.92899V10.7678H16L15.1798 13.1442H12.9422V13.1391Z"
            fill="#A7AFDD"
        />
    </svg>
);

export default FacebookSecondIcon;
