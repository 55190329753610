import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {formatCurrency, formatDateTime} from "../../util/util";
import './cabinet.css';
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";

class Finances extends BasePage {
    state = {
        loadingHistory: true,
        page: 1
    };

    componentDidMount() {
        this.fetchHistory(this.state.page);
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/get_finance_history', {page: page});

        if (res.data && Array.isArray(res.data.financeHistory))
            this.setState({loadingHistory: false, page: page, isLast: res.data.isLast, financeHistory: res.data.financeHistory});
    }

    renderPage() {
        const { loadingHistory, financeData, financeHistory, page, isLast} = this.state;

        return (
            <RestrictedArea allowedTo={UserGroup.SUPERUSER}>
            <div className="page">
                <h1>Финансовая история</h1>

                <div style={{marginTop: '30px'}}>
                    <div className='table-responsive perfect-scrollbar'>
                        <table className="table" style={{minWidth: 700}}>
                            <thead>
                                <tr>
                                    <th>Время</th>
                                    <th>Пользователь</th>
                                    <th>Сумма</th>
                                    <th>Описание</th>
                                </tr>
                            </thead>
                            <tbody>
                                {financeHistory && financeHistory.map((hist,i) => (
                                    <tr key={i}>
                                        <td>{formatDateTime(hist.time)}</td>
                                        <td>{hist.user_name}</td>
                                        <td>{formatCurrency(hist.amount)}</td>
                                        <td>{hist.comment}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="footer">
                        <Pages style={{margin: '0 auto', width: 'auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={page} isLast={isLast}/>
                    </div>
                </div>

            </div>
            </RestrictedArea>
        );
    }
}



export default Finances;
