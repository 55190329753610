import { Link, useLocation } from "react-router-dom";
import { RestrictedArea } from "../../wrappers/RestrictedArea";
import ChevronRight from "../icons/ChevronRight";
import { useMemo, useRef, useState } from "react";
import "./sidebarItem.css";

const SidebarItem = ({
    title,
    path,
    icon,
    children,
    accessLevel,
    closeMobileMenu,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const contentRef = useRef(null);

    const location = useLocation();

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const groupClassName = useMemo(() => {
        let className = "sidebar-item-group sidebar-item ";
        if (!children) return className;

        if (isOpen) {
            className += "open";
        }

        if (window.location.pathname.includes(path)) {
            className += " includes-path";
        }
        return className;
    }, [location, isOpen]);

    if (!title) return;

    if (children) {
        return (
            <RestrictedArea allowedTo={accessLevel}>
                <div className={groupClassName}>
                    <div className="link" onClick={toggleOpen}>
                        <div className="left-block group-title">
                            <div className="sidebar-item-icon">{icon}</div>
                            <h3>{title}</h3>
                        </div>
                        <div className="chevron">
                            <ChevronRight />
                        </div>
                    </div>
                    <div
                        className="sidebar-item-group-list"
                        ref={contentRef}
                        style={{
                            height: contentRef.current?.scrollHeight + "px",
                        }}
                    >
                        {children.map((item, index) => (
                            <SidebarItem
                                key={index}
                                title={item.title || item.menu}
                                path={path + "/" + item.path}
                                accessLevel={item.accessLevel}
                                icon={<div className="circleIcon" />}
                                closeMobileMenu={closeMobileMenu}
                            />
                        ))}
                    </div>
                </div>
            </RestrictedArea>
        );
    }

    return (
        <RestrictedArea allowedTo={accessLevel}>
            <div
                className={`sidebar-item ${
                    location.pathname === path ? "active" : ""
                }`}
            >
                <Link to={path} className="link" onClick={closeMobileMenu}>
                    <div className="left-block">
                        <div className="sidebar-item-icon">{icon}</div>
                        <h3>{title}</h3>
                    </div>
                </Link>
            </div>
        </RestrictedArea>
    );
};

export default SidebarItem;
