const InputWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    min = "",
    max = "",
    type = "text",
    disallowedSymbols = [],
    onBlur = () => {},
}) => (
    <div className="filed-block">
        <label>{label}</label>
        <input
            type={type}
            min={min}
            max={max}
            value={value || ""}
            onBlur={onBlur}
            onChange={(e) => onChange(fieldName, e.target.value)}
            onKeyDown={(e) => {
                if (disallowedSymbols.includes(e.key)) {
                    e.preventDefault();
                }
            }}
        />
    </div>
);

export default InputWithLabel;
