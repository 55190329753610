import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import {formatClientName, formatCurrency, formatDateTime, getResponseErrorsList} from "../../util/util";
import Select from "react-select";
import BasePage from "../base/BasePage";
import SelectClient from "../../widgets/admin/SelectClient";
import SelectUser from "../../widgets/admin/SelectUser";
import Pages from "../../widgets/Pages";
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyIcon from '../../components/icons/CopyIcon';

class WalletExplorer extends BasePage {
    state = {
        loading: true,
        loadingWallet: true,
        selectedWalletID: -1,
        page: 1
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/wallet_getAllWallets');

        if (res.data) {
            const walletID = (res.data.wallets && res.data.wallets.length > 0) ? res.data.wallets[0].id : -1;
            this.setState({
                loading: false, wallets: res.data.wallets,
                selectedWalletID: walletID
            });

            if (walletID > 0) await this.fetchWalletData(walletID, 1);
        }
    }

    async fetchWalletData(walletID, page) {
        this.setState({loadingWallet: true, page: page});

        let res = await axios.post('/wallet_getData', {id: walletID, page: page});

        if (res.data)
            this.setState({loadingWallet: false, walletData: res.data});
    }

    onWalletSelected(walletOpt) {
        this.setState({ errors: {}, selectedWalletID: walletOpt.value, page: 1 });
        this.fetchWalletData(walletOpt.value, 1);
    }


    renderPage() {
        const { loading, loadingWallet, wallets, selectedWalletID, walletData, page} = this.state;

        /*{ errors &&
        <div className="error holder"><div className="message">{errors}</div></div>
        }*/
        let walletsOpt = wallets ? wallets.map(w => ({
            value: w.id,
            label: w.name
        }) ) : [];
        let walletsSel = walletsOpt.filter(w => w.value === selectedWalletID);
        walletsSel = (walletsSel.length>0) ? walletsSel[0] : null;

        return (
            <div className="page deposit-funds">
                <h1>Кошелек</h1>
                <div className={'center-section'} style={{marginTop: 16}}>
                    <Select
                        className={'select'}
                        options={walletsOpt}
                        value={walletsSel}
                        onChange={this.onWalletSelected.bind(this)}
                        isLoading={loading}
                        isDisabled={loadingWallet}
                    />
                </div>

                {walletData &&
                <div style={{marginTop: '20px'}}>
                    <div className='wallet-info'>
                        <div>
                            <span>Кошелек <b>{walletData.name}</b>: </span>
                            <div className={'readOnlyFrame tokenFrame'}>
                                <CopyToClipboard text={walletData.addr}>
                                    <button type="button" className='copy-btn' data-tip="Скопировать">  <CopyIcon /> </button>
                                </CopyToClipboard> 
                                {walletData.addr}
                            </div>
                        </div>
                        <div >
                            Баланс: <span className={'green'}><b>{walletData.balance}</b> USDT</span>
                        </div>
                    </div>
                    <div className='table-responsive perfect-scrollbar'>
                        <table className="table" style={{width: 'auto', margin: '0 auto'}}>
                            <thead>
                                <tr>
                                    <th>Время</th>
                                    <th >Сумма</th>
                                    <th>Transaction ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {walletData.transactions && walletData.transactions.map((tr,i) => (
                                    <tr key={i}>
                                        <td>{formatDateTime(tr.time)}</td>
                                        <td className={(tr.value>0 ? (tr.value>=1 ? 'green' : 'gray') : 'red') }>
                                            {tr.value>0 ? '+' : ''}{tr.value}</td>
                                        <td>
                                            <a className={'soft'} href={`https://tronscan.org/#/transaction/${tr.transaction_id}`} target='_blank'>{tr.transaction_id}</a></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="footer">
                        <Pages style={{margin: '0 auto', width: 'auto'}} handler={this.fetchWalletData.bind(this, selectedWalletID)}
                               enabled={!loadingWallet} current={page} isLast={walletData.isLastTransactions}/>
                    </div>
                </div>

                }

            </div>
        );
    }
}


export default WalletExplorer;
