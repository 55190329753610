import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {formatClientName, formatCurrency, formatDateTime, formatPercent} from "../../util/util";
import './cabinet.css';
import BasePage from "../base/BasePage";
import Pages from "../../widgets/Pages";

class Referrals extends BasePage {
    state = {
        loading: true,
        loadingHistory: true,
        page: 1
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/get_referral_data');

        if (res.data) {
            this.setState({loading: false, referral_reward_rate: res.data.referral_reward_rate, referrals: res.data.referrals});

            if (res.data.referrals.length > 0) {
                this.fetchHistory(this.state.page);
            }
        }
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/get_referral_finance_history', {page: page});

        if (res.data && Array.isArray(res.data.referralFinanceHistory))
            this.setState({loadingHistory: false, page: page, isLast: res.data.isLast, referralFinanceHistory: res.data.referralFinanceHistory});
    }

    renderPage() {
        const { loadingHistory, referral_reward_rate, referrals, referralFinanceHistory, page, isLast } = this.state;

        let total_reward_from_all_referrals;
        if (referrals) total_reward_from_all_referrals = formatCurrency(
            referrals.reduce((sum,r) => (sum + (r.total_reward_from_referral ? parseFloat(r.total_reward_from_referral) : 0.0)),0.0)
        );

        return (
            <RestrictedArea allowedTo={UserGroup.SUPERUSER}>
            <div className="page">
                <h1>Реффералы</h1>

                { referrals &&
                <div>
                    {referrals.length === 0 ?
                    <h2>
                        У Вас есть возможность пригласить других участников в систему и зарабатывать от <b>5%</b> до <b>20%</b> от их денежных поступлений.
                        <br/>Чтобы зарегистрировать реферала, свяжитесь с Администрацией.
                    </h2>
                    :
                    <div style={{marginTop: 16}}> 
                        <h3> Ваш доход от поступления средств рефералов: <b>{formatPercent(referral_reward_rate)}</b></h3>
                        <h3 style={{marginBottom: 0}}>Всего заработано: <b>{total_reward_from_all_referrals}</b></h3>
                        <div>
                            <h2>Мои рефералы:</h2>
                            <div className='table-responsive perfect-scrollbar'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Время регистрации</th>
                                            <th>Реферал</th>
                                            <th>Общий оборот<br/>реферала</th>
                                            <th>
                                                Инсталлов обработано
                                                <div className="smallText">(инсталлов всего)</div>
                                            </th>
                                            <th>Общий заработок<br/>от реферала</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referrals.map(r => (
                                            <tr key={r.name}>
                                                <td>{formatDateTime(r.reg_time)}</td>
                                                <td>{r.name}</td>
                                                <td>
                                                    {formatCurrency(r.total_turnover)}
                                                </td>
                                                <td>
                                                    {r.total_installs}
                                                    <div className="smallText">({r.total_installs_recalc})</div>
                                                </td>
                                                <td>
                                                    {formatCurrency(r.total_reward_from_referral ? r.total_reward_from_referral : 0)}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        { !loadingHistory &&
                        <div>
                            <h2>Финансовая история</h2>
                            <div className='table-responsive perfect-scrollbar'>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Время</th>
                                            <th>Реферал</th>
                                            <th>Сумма</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {referralFinanceHistory && referralFinanceHistory.map((hist,i) => (
                                            <tr key={i}>
                                                <td>{formatDateTime(hist.time)}</td>
                                                <td>{hist.referral}</td>
                                                <td>{formatCurrency(hist.amount)}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="footer">
                                <Pages style={{margin: '0 auto', width: 'auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={page} isLast={isLast}/>
                            </div>
                        </div>
                        }
                    </div>
                    }
                </div>
                }
            </div>
            </RestrictedArea>
        );
    }
}



export default Referrals;
