import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatClientName, formatCurrency, getResponseErrorsList} from "../../util/util";
import Select from "react-select";
import './admin.css';
import BasePage from "../base/BasePage";
import {UserContext, UserGroup} from "../../context/User";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import Button from '../../components/UI/Button';
import Textarea from '../../components/UI/Textarea';

class AdminGameStats extends BasePage {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            data: [],
            domainlist: [],
            ips: [],
            restartDisabled: false,
            selectedNodeID: -1,
            nodeIPs: [],
            loading: true,
            loadingNodeIPs: true,
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    isAdmin() {
        return this.getUser().hasPermission(UserGroup.ADMIN);
    }

    async fetchData() {
        if (this.isAdmin()) {
            this.setState({loading: true});

            let res = await axios.post('/admin_get_node_ids');

            if (res.data)
                this.setState({loading: false, ...res.data});
        } else {
            this.setState({loading: false});
        }
    }

    async fetchNodeIPs(nodeID) {
        this.setState({loadingNodeIPs: true});

        let res = await axios.post('/admin_get_node_ips', {node_id: nodeID});

        if (res.data)
            this.setState({loadingNodeIPs: false, ...res.data});
    }

    onDomainListChange(e) {
        this.setState({domainlist: e.target.value.match(/[^\r\n]+/g)});
    }

    async onCheckDomains(e) {
        try {
            this.setState({errors: null, ips: []});
            let res = await axios.post('/admin_get_domain_ips', {domainlist: this.state.domainlist});

            if (res.data)
                this.setState({ips: res.data.ips});
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async onCronRestart(e) {
        try {
            setTimeout(() => {
                this.setState({restartDisabled: false});
            }, 5000);
            this.setState({errors: null, restartDisabled: true});
            axios.post('/admin_restart', {service: 'cron'});
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async onCronFBRestart(e) {
        try {
            setTimeout(() => {
                this.setState({restartDisabled: false});
            }, 5000);
            this.setState({errors: null, restartDisabled: true});
            axios.post('/admin_restart', {service: 'cron-fb'});
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    onNodeSelected(newNodeID) {
        this.setState({ errors: '', selectedNodeID: newNodeID.value });
        //this.fetchNodeIPs(newNodeID.value);
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors)
            return this.state.errors[param];
        return "";
    }

    async onAddNewIP(e) {
        if (this.state.selectedNodeID === -1) return;
        e.preventDefault();
        this.setState({errors: ''});
        try {
            await axios.post('/admin_add_node_ip', { node_id: this.state.selectedNodeID, ip: this.state.newip });
            await this.fetchNodeIPs(this.state.selectedNodeID);
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async onRemoveNodeIP(nodeIP,e) {
        if (this.state.selectedNodeID === -1) return;
        e.preventDefault();
        this.setState({errors: ''});
        await axios.post('/admin_remove_node_ip', { node_id: this.state.selectedNodeID, ip: nodeIP });
        await this.fetchNodeIPs(this.state.selectedNodeID);
    }

    async onAddDomain(e) {
        if (this.state.selectedNodeID === -1) return;
        e.preventDefault();
        this.setState({errors: ''});
        try {
            const node_domain = this.state.node_domain;
            await axios.post('/admin_add_domain', { node_id: this.state.selectedNodeID, node_domain: node_domain });
            this.batchTestDomain(node_domain);
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    async onTestDomain(e) {
        e.preventDefault();
        this.setState({errors: ''});
        this.testDomain(this.state.node_domain);
    }

    async batchTestDomain(domain, count= 5) {
        const _this = this;
        if ( count > 0 && ! (await this.testDomain(domain)) ) {
            setTimeout(() => { _this.batchTestDomain(domain, count-1) }, 2500);
        }
    }

    async testDomain(domain) {
        if (!domain) return;
        this.setState({testDomainStatus: <div><i>{domain}</i> ...</div>});
        let httpRes, httpsRes;
        try {
            let res = await axios.post(`http://${domain}/test`);
            if (!res.data || !res.data.toLowerCase().includes('ok')) throw new Error("Test fail");
        } catch (e) {
            this.setState({testDomainStatus: <div><i>{domain}</i>  <span className='red'>HTTP: FAIL ({e.message})</span></div>});
            return false;
        }

        this.setState({testDomainStatus: <div><i>{domain}</i>  <span className='green'>HTTP: OK</span>  HTTPS: ...</div>});

        try {
            let res = await axios.post(`https://${domain}/test`);
            if (!res.data || !res.data.toLowerCase().includes('ok')) throw new Error("Test fail");
            this.setState({testDomainStatus: <div><i>{domain}</i>  <span className='green'>HTTP: OK</span>  <span className='green'>HTTPS: OK</span></div>});
        } catch (e) {
            this.setState({testDomainStatus: <div><i>{domain}</i>  <span className='green'>HTTP: OK</span>  <span className='red'>HTTPS: FAIL ({e.message})</span></div>});
            return false;
        }

        return true;
    }

    async onRestartNode(e) {
        if (this.state.selectedNodeID === -1) return;
        e.preventDefault();
        this.setState({errors: ''});
        try {
            await axios.post('/admin_node_restart', { node_id: this.state.selectedNodeID });
        } catch (err) {
            this.setState({errors: getResponseErrorsList(err)});
        }
    }

    render() {
        const { errors, domainlist, ips, selectedNodeID, restartDisabled, loading, loadingNodeIPs, nodeIPs, testDomainStatus } = this.state;

        const renderError = (fieldName) => (
            <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>
        );

        let selectNodeOptions = this.state.nodeIDs ? this.state.nodeIDs.map(nodeID => ({
            value: nodeID,
            label: nodeID
        }) ) : [];

        return (
            <RestrictedArea allowedTo={UserGroup.MODERATOR}>
            <div className="page server-setting" style={{marginBottom: '100px'}}>
                <h1>Установки серверов</h1>

                <div className="form">
                    <div className='field'>
                        <h2>Перезагрузка CRON сервисов</h2>

                        <RestrictedArea allowedTo={UserGroup.ADMIN}>
                        <div style={{margin: '5px 0px 20px'}}>
                            <Button 
                            withoutRounded
                            onClick={this.onCronRestart.bind(this)} 
                            variant="warning" title="Перезагрузить CRON (основной)" 
                            isDisabled={restartDisabled}/>
                        </div>
                        </RestrictedArea>
                        <div style={{marginTop: '5px'}}>
                            <Button
                            onClick={this.onCronFBRestart.bind(this)}
                            isDisabled={restartDisabled}
                            withoutRounded
                            variant="warning"
                            title="Перезагрузить CRON-FB (пошарка кабинетов)"/>
                        </div>
                    </div>


                    <RestrictedArea allowedTo={UserGroup.ADMIN}>
                    <div className='field'>
                        <h2>Сетевые настройки на узле</h2>

                        <Select
                            className="select"
                            onChange={this.onNodeSelected.bind(this)}
                            options={selectNodeOptions}
                            isLoading={loading}
                            placeholder={'-- Выберите узел --'}
                        />

                        {selectedNodeID !== -1 && <div>
                            <div className='domain-func'>
                                <input id="node_domain" name="node_domain" type="text" placeholder='domain.com' onChange={this.onFieldChanged.bind(this)} />
                                <Button title="Добавить сертификат" size='small' variant="warning" withoutRounded onClick={this.onAddDomain.bind(this)}/>
                                <Button title="Тест домена" size='small' variant="warning" withoutRounded onClick={this.onTestDomain.bind(this)} />
                            </div>
                            <div className='inRow leftAlign' style={{marginTop: '15px'}}>
                                <Button title="Перезагрузить узел" size='small' variant="warning" withoutRounded  onClick={this.onRestartNode.bind(this)} />
                            </div>
                            <div className='inRow leftAlign' style={{marginTop: '15px'}}>
                                <span id="test_domain" className="smallText">{testDomainStatus}</span>
                            </div>
                            { renderError("node_common") }

                            {/*<ul className={"smallList"}>
                                {!loadingNodeIPs && nodeIPs &&
                                nodeIPs.map((nodeIP, index) => {
                                    const doRemove = this.onRemoveNodeIP.bind(this, nodeIP.ip);
                                    return <li key={index}
                                               className={`smallItem ${nodeIP.state === NodeIPState.NEW ? 'gray' : ''}`}>{nodeIP.ip}{
                                        <button onClick={doRemove} className={'listSmallButton'}>
                                            <div className="small2Icon crossSmall2Icon"/>
                                        </button>}</li>;
                                })
                                }
                            </ul>

                            <div className='inRow leftAlign' style={{marginTop: '5px'}}>
                            <input id="newip" name="newip" type="text" placeholder='x.x.x.x' onChange={this.onFieldChanged.bind(this)} />
                            <button onClick={this.onAddNewIP.bind(this)} className="common small2">Добавить</button>
                            </div>*/}
                        </div>}
                        { renderError("newip") }
                    </div>


                    <div className='field'>
                        <h2>IP доменов</h2>

                        <div><Textarea id="domainlist" className="wide" style={{height: '160px'}} onChange={this.onDomainListChange.bind(this)}/>
                            { renderError("domainlist") }
                        </div>

                        <div className='readOnlyFrame leftAlign'>
                            {ips.map(ip=> <div style={{break: 'both'}} key={ip}>{ip}</div>)}
                        </div>

                        <div style={{marginTop: '10px'}}>
                            <Button 
                            title="Проверить домены" 
                            variant="warning" withoutRounded   
                            onClick={this.onCheckDomains.bind(this)} 
                            isDisabled={!domainlist.length}/>
                        </div>
                    </div>
                    </RestrictedArea>


                    { renderError("common") }
                </div>


            </div>
            </RestrictedArea>
        );
    }
}
AdminGameStats.contextType = UserContext;

export default AdminGameStats;
