import React, {useContext} from 'react';
import {UserContext} from "../context/User";
import {Route} from "react-router-dom";

const isRestricted = (user, props) => {
    const {allowedTo,allowedToGroupOnly,allowedIfOrganicsAvailable} = props;
    return ((typeof allowedToGroupOnly !== 'undefined' && allowedToGroupOnly === user.group)
        || (typeof allowedTo !== 'undefined' && user.hasPermission(allowedTo))
        || (typeof allowedIfOrganicsAvailable !== 'undefined' && allowedIfOrganicsAvailable && user.isOrganicsAvailable()));
}

const RestrictedArea = (props) => {
    const user = useContext(UserContext);
    return isRestricted(user, props) ? props.children : null;
};

const RestrictedRoute = (user, props) => {
    const {path, element} = props;
    return isRestricted(user, props) ? <Route key={path} path={path} element={element}/> : null;
}

// [DANGER] Just for initial create
/*RestrictedArea = (props) => {
    return props.children;
};*/
// ~~

export { RestrictedArea, RestrictedRoute };