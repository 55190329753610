import React, { Component } from "react";
import axios from "axios";
import "../../css/table.css";
import { formatDateTime } from "../../util/util";
import { RestrictedArea } from "../../wrappers/RestrictedArea";
import { UserContext, UserGroup } from "../../context/User";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Button from "../../components/UI/Button";
import TelegramIcon from "../../components/icons/TelegramIcon";

class TelegramSettings extends Component {
    state = {
        loading: true,
        authorizedAccounts: [],
        telegramAuthToken: "",
    };

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({ loading: true });

        let res = await axios.post("/telegram_get_settings");

        if (res.data) {
            this.setState({ loading: false, ...res.data });
        }
    }

    async onRenewToken(e) {
        try {
            this.setState({ loading: true, telegramAuthToken: "" });
            let res = await axios.post("/telegram_renew_auth_token");

            if (res.data) {
                this.setState({ loading: false, ...res.data });
            }
        } catch (err) {}
    }

    render() {
        const { loading, telegramAuthToken, authorizedAccounts } = this.state;

        return (
            <RestrictedArea allowedTo={UserGroup.USER}>
                <div className="page telegram-page ">
                    <div
                        className="card center-section"
                        style={{ maxWidth: 640 }}
                    >
                        <div className="card-header">Telegram Bot</div>
                        <div className="card-body">
                            <ul className="timeline ">
                                <li className="timeline-item timeline-item-transparent">
                                    <span className="timeline-point timeline-point-info"></span>
                                    <div className="timeline-event">
                                        <div className="timeline-header">
                                            <h6 className="">Open Bot</h6>
                                        </div>
                                        <div className="block-ui-btn demo-inline-spacing">
                                            <p className="desc">
                                                Для подключения бота вам
                                                необходимо открыть @da_apps_bot
                                                и запустить его :
                                            </p>
                                        </div>
                                        <hr />
                                        <div className="telegram-block-wrapper">
                                            <div className="avatar-block">
                                                <div className="avatar me-3">
                                                    <div />
                                                </div>
                                                <div>
                                                    <p className="">
                                                        Deeplink agency
                                                    </p>
                                                    <span className="text-muted">
                                                        @da_apps_bot
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="telegram-link-wrapper">
                                                <a href="tg://resolve?domain=da_apps_bot">
                                                    <TelegramIcon />
                                                    Telegram
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-item timeline-item-transparent">
                                    <span className="timeline-point timeline-point-warning"></span>
                                    <div className="timeline-event">
                                        <div className="timeline-header">
                                            <h6 className="mb-0">
                                                Authorization Token
                                            </h6>
                                        </div>
                                        <div className="second-block">
                                            <small className="mb-3">
                                                Скопируйте ваш токен авторизации
                                                :
                                            </small>
                                            <input
                                                style={{ width: "100%" }}
                                                className="readOnlyCode"
                                                value={telegramAuthToken}
                                                readOnly
                                            />
                                            <div className="buttons-group">
                                                {authorizedAccounts?.length ? (
                                                    <div />
                                                ) : (
                                                    <Button
                                                        onClick={this.onRenewToken.bind(
                                                            this
                                                        )}
                                                        title="Сбросить Token"
                                                        variant="error"
                                                        size="small"
                                                        style={{
                                                            borderRadius: 6,
                                                        }}
                                                    />
                                                )}
                                                <CopyToClipboard
                                                    text={telegramAuthToken}
                                                >
                                                    <Button
                                                        title="Copy"
                                                        variant="success"
                                                        size="small"
                                                        style={{
                                                            borderRadius: 6,
                                                        }}
                                                    />
                                                </CopyToClipboard>
                                            </div>
                                            <div className="block-ui-btn demo-inline-spacing">
                                                <p className="desc">
                                                    И отправьте Token боту...
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="timeline-item timeline-item-transparent border-0">
                                    <span className="timeline-point timeline-point-success"></span>
                                    <div className="timeline-event">
                                        <div className="timeline-header">
                                            <h6 className="mb-0">Complete</h6>
                                        </div>
                                        <div className="block-ui-btn demo-inline-spacing">
                                            <p className="desc">
                                                С помощью бота Вы можете :
                                            </p>
                                            <small
                                                style={{
                                                    marginBottom: 0,
                                                    lineHeight: 1.47,
                                                }}
                                            >
                                                - Добавлять Facebook кабы в
                                                приложение
                                                <br />- Получать информацию о
                                                доступных прилах
                                            </small>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="table-responsive perfect-scrollbar">
                        {authorizedAccounts.length ? (
                            <div>
                                <h2>Подключенные к боту акаунты:</h2>
                                <div className="table-responsive perfect-scrollbar">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Имя</th>
                                                <th>Пользователь</th>
                                                <th>Зарегистрирован</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {authorizedAccounts.map(
                                                (account, i) => (
                                                    <tr key={i}>
                                                        <td>{ account.tg_first_name }</td>
                                                        <td>@{ account.tg_username }</td>
                                                        <td>{`${formatDateTime( account.time )}`}</td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                    }}
                                >
                                    <Button
                                        onClick={this.onRenewToken.bind(this)}
                                        title="Сбросить авторизации и обновить токен"
                                        variant="error"
                                        size="small"
                                        style={{
                                            borderRadius: 6,
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </RestrictedArea>
        );
    }
}
TelegramSettings.contextType = UserContext;

export default TelegramSettings;
