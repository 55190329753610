import "./languagesTabs.css";
import CloseIcon from "../icons/CloseIcon";

const LanguagesTabs = ({
    addLanguage,
    activeLng,
    setActiveLng,
    deleteLng,
    items,
    languagesList,
}) => (
    <>
        <div
            className={`languages-tabs-container ${
                items ? "without-border-b" : ""
            } ${items && Object.keys(items).length ? "" : "animate-add-btn"}`}
        >
            <div className="languages-tabs">
                {items &&
                    Object.keys(items).map((key) => (
                        <div
                            key={key}
                            className={`languages-tab ${
                                key === activeLng ? "active" : ""
                            }`}
                            onClick={() => setActiveLng(key)}
                        >
                            {key}
                            <button
                                type="button"
                                className="close-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    deleteLng(key);
                                }}
                            >
                                <CloseIcon />
                            </button>
                        </div>
                    ))}
                {items &&
                    languagesList &&
                    Object.keys(items).length !==
                        Object.keys(languagesList).length && (
                        <button
                            id="add-language-btn"
                            className="languages-tab add-tab"
                            onClick={addLanguage}
                        >
                            <CloseIcon />
                        </button>
                    )}
                {!items && languagesList && (
                    <button
                        id="add-language-btn"
                        className="languages-tab add-tab"
                        onClick={addLanguage}
                    >
                        <CloseIcon />
                    </button>
                )}
            </div>
        </div>
    </>
);

export default LanguagesTabs;
