import { useState } from "react";
import ModalWindow from "../../widgets/ModalWindow";
import EditIcon from "../../components/icons/EditIcon";
import Button from "../../components/UI/Button";

const LandingModal = ({ languages = [], name, spins, bonus }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [dynamicFields, setDynamicFields] = useState({
        spins: spins || "",
        bonus: bonus || "",
    });

    const updateDynamicField = (key, event) => {
        setDynamicFields((prev) => ({ ...prev, [key]: event.target.value }));
    };

    const closeModal = () => setIsOpen(false);
    const openModal = () => setIsOpen(true);

    const saveData = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        setIsDisabled(true);

        setTimeout(() => {
            console.log(dynamicFields);
            setIsDisabled(false);
            closeModal();
        }, 1000);
    };

    return (
        <div id="landing-modal-wrapper">
            <button className="edit-btn" onClick={openModal} type="button">
                <EditIcon />
            </button>
            <ModalWindow
                isVisible={isOpen}
                onClose={closeModal}
                anchorContainerID="landing-modal-wrapper"
                closeButton
                className="landing-modal"
            >
                <form onSubmit={saveData}>
                    <h3>{name}</h3>
                    <div className="landing-modal-row">
                        <label>Доступные гео: {languages.join(", ")}</label>
                    </div>

                    <div className="landing-modal-row">
                        <label>Бонус:</label>
                        <input
                            type="text"
                            required
                            value={dynamicFields.bonus}
                            onChange={(e) => updateDynamicField("bonus", e)}
                        />
                    </div>
                    <div className="landing-modal-row">
                        <label>Spins:</label>
                        <input
                            type="number"
                            required
                            value={dynamicFields.spins}
                            onChange={(e) => updateDynamicField("spins", e)}
                        />
                        <div className="tip">
                            Значения полей измениться только на данном потоке
                        </div>
                    </div>
                    <Button
                        style={{ marginTop: 10 }}
                        title="Сохранить"
                        withoutRounded
                        size="small"
                        type="submit"
                        isLoading={isDisabled}
                    />
                </form>
            </ModalWindow>
        </div>
    );
};

export default LandingModal;
