import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";
import {UserContext, UserGroup} from "../../context/User";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import AdminGameStats from "./ServerSettings";
import Loader from '../../components/UI/Loader';

class ClientStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            data: []
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    isAdmin() {
        return this.getUser().hasPermission(UserGroup.ADMIN);
    }

    isPlatformAdmin() {
        return this.getUser().isInGroup(UserGroup.PLATFORM_ADMIN);
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/client_stats');

        if (res.data)
            this.setState({loading: false, platforms: res.data.platforms, clients: res.data.clients});
    }

    render() {
        const { loading, clients, platforms } = this.state;

        const installsSpeedIcon = (installs_6h, installs_24h, useEmptyIcon) => {
            const rate = 4 * installs_6h / installs_24h;
            let iconClass= "stableIcon"; /* useEmptyIcon ? "stableIcon" : null;*/
            if (rate >= 2.0) iconClass = "up2Icon";
            else if (rate >= 1.1) iconClass = "upIcon";
            else if (rate <= 0.5) iconClass = "down2Icon";
            else if (rate <= 0.9) iconClass = "downIcon";
            return iconClass ? <div className={iconClass}/> : null;
        }

        const isAdmin = this.isAdmin();
        const isPlatformAdmin = this.isPlatformAdmin();

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page">
                <h1>Статистика по клиентам</h1>

                {!loading && platforms.map(platform =>
                <div key={platform.id} className="table-responsive perfect-scrollbar">
              
                        <RestrictedArea allowedTo={UserGroup.MODERATOR}>
                        <h2>Платформа {platform.name}</h2>
                        </RestrictedArea>
                        <table className="table">
                        <thead>
                            <tr>
                                <th>Клиент</th>
                                <th>Баланс</th>
                                <th>Цена инсталла<div className="smallText">(в данный момент)</div></th>
                                {isAdmin &&
                                <th>Общий оборот</th>
                                }
                                <th><div className="smallerText">Пользователь</div></th>
                                {(isAdmin || isPlatformAdmin) ?
                                <th>Инсталлов всего
                                    <div className="smallText">(<span className="green">за 24ч</span>)</div></th>
                                    :
                                <th>Инсталлов за 24ч</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {clients.filter(c => c.platform_id === platform.id).map(row => (
                                <tr key={row.name}>
                                    <td>
                                        <div>{row.name}</div>
                                        <div className="smallText">{row.contact_person}</div>
                                    </td>
                                    <td>{formatCurrency(row.balance)}</td>
                                    <td>{formatCurrency(row.install_price)}</td>
                                    {isAdmin &&
                                    <td>{formatCurrency(row.total_turnover)}</td>
                                    }
                                    <td>{row.login}</td>
                                    {(isAdmin || isPlatformAdmin) ?
                                    <td>
                                        {row.total_installs}
                                        <div className="smallText">
                                            {row.installs_24h > 0 &&
                                                <div>{'('}{installsSpeedIcon(row.installs_6h,row.installs_24h)
                                                    }<span className="green">{row.installs_24h}</span>)</div>
                                            }
                                        </div>
                                    </td>
                                    :
                                    <td>
                                        {row.installs_24h > 0 ?
                                            <div>{installsSpeedIcon(row.installs_6h,row.installs_24h,true)}
                                            <span className="green">{row.installs_24h}</span>
                                            </div>
                                                : 0
                                        }
                                    </td>
                                    }
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                )}

                {loading && <Loader/>}
            </div>
            </RestrictedArea>
        );
    }
}
ClientStats.contextType = UserContext;

export default ClientStats;
