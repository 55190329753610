import React, {useContext} from 'react';
import {NavLink, Outlet, Route} from "react-router-dom";
import ClientStats from "./ClientStats";
import AdminGameStats from "./AdminGameStats";
import AdminSingleGameStats from "./AdminSingleGameStats";
import CreateNewClient from "./CreateNewClient";
import {UserContext, UserGroup} from "../../context/User";
import {RestrictedArea, RestrictedRoute} from "../../wrappers/RestrictedArea";
import ManageGames from "./ManageGames";
import DepositFunds from "./DepositFunds";
import EditClientSettings from "./EditClientSettings";
import ServerSettings from "./ServerSettings";
import OptimizeGames from "./OptimizeGames";
import WalletExplorer from "../special/WalletExplorer";
import { useNavigate } from 'react-router-dom';

export const adminPages = [
    { path: 'clientstats', icon: 'bar-chart', menu: 'Статистика по клиентам', element: <ClientStats/>, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: 'gamestats', icon: 'bar-chart', menu: 'Статистика по прилкам', element: <AdminGameStats/>, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: 'newclient', icon: 'user', element: <CreateNewClient/>, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: 'editclient', icon: 'user', menu: 'Управление клиентами', element: <EditClientSettings/>, accessLevel: UserGroup.MODERATOR },
    { path: 'managegames', icon: 'gp-app', menu: 'Управление приложениями', element: <ManageGames/>, accessLevel: UserGroup.PLATFORM_ADMIN },
    /*{ path: '/optimize', icon: 'opt', title: 'Оптимизация', element: OptimizeGames, accessLevel: UserGroup.ADMIN },*/
    { path: 'deposit', icon: 'money-with-wings', menu: 'Пополнить счет клиенту', element: <DepositFunds/>, accessLevel: UserGroup.PLATFORM_ADMIN },
    { path: 'wallet', icon: 'wallet', menu: 'Кошельки', element: <WalletExplorer/>, accessLevel: UserGroup.ADMIN },
    { path: 'serversettings', icon: 'gear', menu: 'Установки серверов', element: <ServerSettings/>, accessLevel: UserGroup.MODERATOR },

    { path: 'singlegamestats', element: <AdminSingleGameStats/>, accessLevel: UserGroup.PLATFORM_ADMIN },
];

let AdminPortal = () => (
    <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
        <div className="right-menu-page">
            <div className="menu">
                <div className="menu-list">
                    {adminPages.map(menuItem => menuItem.menu &&
                        <RestrictedArea key={menuItem.path} allowedTo={menuItem.accessLevel}>
                            <NavLink to={menuItem.path} activeClassName="active" className="admin" key={menuItem.path}>{ menuItem.icon &&
                                <div className={'icon'} style={{backgroundImage: `url(/icons/${menuItem.icon}.png`}}/>
                            }<div>{menuItem.menu}</div></NavLink>
                        </RestrictedArea>
                    )}
                </div>
            </div>
            <Outlet/>
        </div>
    </RestrictedArea>
);

const AdminRoutes = () => {
    const user = useContext(UserContext);
    return <Route path='/admin/*'>{
        adminPages.map(menuItem =>
            RestrictedRoute(user, {allowedTo: menuItem.accessLevel, path: menuItem.path, element: menuItem.element})
        )
    }</Route>
}

export {AdminRoutes};
