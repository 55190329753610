import React, { useEffect, useState } from "react";
import Tabs from "../../components/UI/Tabs";
import Description from "./Description";
import "./style.css";
import Button from "../../components/UI/Button";
import Comments from "./Comments";
import Loader from "../../components/UI/Loader";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import InputWithLabel from "../../components/UI/InputWithLabel";
import Settings from "./Settings";
import { StoreID } from "../../common/commonTypes";
import axios from "axios";
import { INIT_DATA, LANGUAGES } from "../../constants/pwa";
import { getResponseErrorsList } from "../../util/util";


const validationFormData = (data) => {
    if (!data.name) {
        return { error: "Поле 'Название' является обязательным." };
    }
};


const CreateEditPwa = ({ isEditMode }) => {
    const [data, setData] = useState(isEditMode ? null : INIT_DATA);
    const [isDisabledBtn, setIsDisabledBtn] = useState(false);
    const [languagesList, setLanguagesList] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [activeTab, setActiveTab] = useState("Описание");
    const [errorData, setErrorData] = useState(null);

    const updateData = (key, value) => {
        setData((prev) => ({ ...prev, [key]: value }));
    };

    const location = useLocation();
    const navigate = useNavigate();

    const createErrorMessage = (value) => {
        setErrorData(value);

        setTimeout(() => {
            setErrorData(null);
        }, 3000);
    };

    const onSubmit = async () => {
        const errorMessage = validationFormData(data);

        if (errorMessage) {
            createErrorMessage(errorMessage);
            return;
        }
        const sendData = {};
        const dataCopy = { ...data };

        sendData.name = dataCopy.name;
        delete dataCopy.name;

        if (isEditMode) {
            sendData.app_id = dataCopy.id;
            delete dataCopy.id;
        } else {
            sendData.appType = StoreID.PWA;
        }

        sendData.store_info = JSON.stringify(dataCopy);
        setIsDisabledBtn(true);
        try {
            if (!isEditMode) {
                await axios.post("/newapp", sendData);
            } else {
                await axios.post("/updateapp", sendData);
            }

            navigate("/apps");
        } catch (err) {
            createErrorMessage(getResponseErrorsList(err));
        }

        setIsDisabledBtn(false);
    };

    const tabItems = [
        {
            label: "Описание",
            children: (
                <Description
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                />
            ),
        },
        {
            label: "Комментарии",
            children: (
                <Comments
                    data={data}
                    updateData={updateData}
                    languagesList={languagesList}
                    createErrorMessage={createErrorMessage}
                />
            ),
        },

        {
            label: "Настройки",
            children: (
                <Settings
                    data={data}
                    updateData={updateData}
                    templates={templates}
                />
            ),
        },
    ];

    useEffect(() => {
        const getLanguages = async () => {
            try {
                const { data } = await axios.post("/app_allowed_languages");
                const languages = {};

                data.forEach((lang) => {
                    if (LANGUAGES[lang]) {
                        languages[lang] = LANGUAGES[lang];
                    }
                });
                setLanguagesList(languages);
            } catch (err) {
                console.log(err);
            }
        };

        const getTemplates = async () => {
            try {
                const {
                    data: { pwa_templates },
                } = await axios.post("/newapp_data");
                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );
            } catch (err) {
                console.log(err);
            }
        };

        const getPwaData = async () => {
            try {
                const { id } = queryString.parse(location.search);
                const {
                    data: { app, pwa_templates },
                } = await axios.post("/editapp_data", {
                    app_id: id,
                });

                let pwa = {
                    id: app.id,
                    name: app.name,
                };

                if (app.store_info) {
                    pwa = { ...pwa, ...JSON.parse(app.store_info) };
                }

                setTemplates(
                    pwa_templates.map((name) => ({
                        label: name,
                        value: name,
                    }))
                );

                setData(pwa);
            } catch (err) {
                console.log(err);
            }
        };

        if (isEditMode) {
            getPwaData();
        } else {
            getTemplates();
            setData(INIT_DATA);
        }
        getLanguages();
    }, []);

    if (!data) {
        return <Loader />;
    }

    return (
        <div className="page create-edit-pwa" id="create-edit-pwa">
            <h1>{isEditMode ? "Изменить" : "Создать"} PWA</h1>
            <div className="card">
                <div className="four-fields">
                    <InputWithLabel
                        label="Название в сервисе"
                        value={data.name}
                        fieldName="name"
                        onChange={updateData}
                    />
                </div>
                <Tabs
                    items={tabItems}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                />
                <div className="footer-block filed-block">
                    <Button
                        withoutRounded
                        title="Сохранить"
                        onClick={onSubmit}
                        isLoading={isDisabledBtn}
                    />
                    {errorData && (
                        <div className="error-block">
                            {Object.values(errorData).map((err) => err)}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CreateEditPwa;
