import "./tabs.css";

const Tabs = ({ items, activeTab, setActiveTab }) => (
    <div className="tabs-container">
        <div className="tabs-wrapper">
            <div className="tabs">
                {items.map((item) => (
                    <div
                        key={item.label}
                        className={`tab ${
                            item.label === activeTab ? "active" : ""
                        }`}
                        onClick={() => setActiveTab(item.label)}
                    >
                        {item.label}
                    </div>
                ))}
            </div>
        </div>
        {items.find((item) => item.label === activeTab)?.children}
    </div>
);

export default Tabs;
