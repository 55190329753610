import ResizeIcon from "../icons/ResizeIcon";

const Textarea = ({
    onChange,
    value,
    disabled,
    required,
    id = "",
    name = "",
    className = "",
    maxLength = "",
    style = {},
}) => (
    <div className="textarea-wrapper">
        <textarea
            className={className}
            value={value}
            onChange={onChange}
            disabled={disabled}
            style={style}
            id={id}
            name={name}
            maxLength={maxLength}
            required={required}
        />
        <div className="textarea-resize">
            <ResizeIcon />
        </div>
    </div>
);

export default Textarea;
