import { useState } from "react";
import { INIT_COMMENT_PWA } from "../../constants/pwa";
import Comment from "./Comment";
import Button from "../../components/UI/Button";
import LanguagesList from "./LanguagesList";
import { getRandomInt } from "../../util/util";

const scrollToBottom = () => {
    setTimeout(() => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }, 0);
};

const Comments = ({ data, updateData, languagesList, createErrorMessage }) => {
    const [isShowLanguagesModal, setIsShowLanguageModal] = useState(false);
    const [activeLng, setActiveLng] = useState(
        data?.comments ? Object.keys(data?.comments)?.[0] || "" : ""
    );

    const addLanguage = ({ lngFrom, lngTo }) => {
        const commentsCopy = data?.comments ? { ...data.comments } : {};

        const id = Date.now();

        commentsCopy[lngTo] = {};
        if (!lngFrom) {
            commentsCopy[lngTo] = {
                [id]: { ...INIT_COMMENT_PWA, like: getRandomInt(200, 500) },
            };
        } else {
            Object.keys(data.comments[lngFrom]).forEach((comment, index) => {
                commentsCopy[lngTo][id + index] = {
                    ...data.comments[lngFrom][comment],
                };
            });
        }

        updateData("comments", commentsCopy);
        setIsShowLanguageModal(false);
        setActiveLng(lngTo);
    };

    const deleteLng = (lng) => {
        const confirmDelete = window.confirm(
            `Вы действительно хотите удалить ${languagesList[lng] || lng}?`
        );

        if (!confirmDelete) return;
        const commentsCopy = { ...data.comments };
        delete commentsCopy[lng];

        updateData("comments", commentsCopy);

        if (activeLng === lng) {
            setTimeout(() => {
                setActiveLng(Object.keys(data.comments)?.[0] || "");
            }, 0);
        }
    };

    const updateComment = (id, comment) => {
        const commentsCopy = JSON.parse(JSON.stringify(data.comments));
        commentsCopy[activeLng][id] = { ...comment };

        updateData("comments", commentsCopy);
    };

    const duplicateComment = (comment) => {
        const commentsCopy = JSON.parse(JSON.stringify(data.comments));
        commentsCopy[activeLng][Date.now()] = { ...comment };

        updateData("comments", commentsCopy);

        scrollToBottom();
    };

    const deleteComment = (id) => {
        const commentsCopy = JSON.parse(JSON.stringify(data.comments));
        delete commentsCopy[activeLng][id];
        updateData("comments", commentsCopy);
    };

    const createCommentDate = () => {
        const lastComment =
            +Object.values(data.comments[activeLng]).reverse()[0].date + 1;

        return lastComment % 3 ? lastComment : lastComment + 1;
    };

    const addComment = () => {
        const commentsCopy = JSON.parse(JSON.stringify(data.comments));
        const date = createCommentDate();
        commentsCopy[activeLng][Date.now()] = {
            ...INIT_COMMENT_PWA,
            like: getRandomInt(200, 500),
            date: String(date),
        };
        updateData("comments", commentsCopy);
        scrollToBottom();
    };

    const content = data?.comments?.[activeLng];

    return (
        <>
            <LanguagesList
                data={data?.comments}
                addLanguage={addLanguage}
                deleteLng={deleteLng}
                activeLng={activeLng}
                setActiveLng={setActiveLng}
                isShowModal={isShowLanguagesModal}
                setIsShowModal={setIsShowLanguageModal}
                languagesList={languagesList}
            />
            <div className="comments-pwa" id="comments-pwa">
                {content && (
                    <div className="comments-list">
                        {Object.keys(content).map((key) => (
                            <Comment
                                key={key}
                                commentData={content[key]}
                                update={(comment) =>
                                    updateComment(key, comment)
                                }
                                deleteComment={() => deleteComment(key)}
                                duplicateComment={duplicateComment}
                                createErrorMessage={createErrorMessage}
                            />
                        ))}
                        <Button
                            size="small"
                            title="Добавить"
                            withoutRounded
                            onClick={addComment}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default Comments;
