import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ duration }) => {
    const [timeLeft, setTimeLeft] = useState(duration);

    useEffect(() => {
        if (timeLeft <= 0) {
            return;
        }

        const intervalId = setInterval(() => {
            if (timeLeft < 0) {
                clearInterval(intervalId);
            } else {
                setTimeLeft(timeLeft - 1);
            }
        }, 1000);

        return () => clearInterval(intervalId);
    }, [timeLeft]);

    const formatTime = () => {
        const _timeLeft = timeLeft > 0 ? timeLeft : 0;
        const hours = Math.floor(_timeLeft / 3600);
        const minutes = Math.floor((_timeLeft % 3600) / 60);
        const seconds = _timeLeft % 60;
        console.log('[QQQ]',[
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':'));
        return [
            hours.toString().padStart(2, '0'),
            minutes.toString().padStart(2, '0'),
            seconds.toString().padStart(2, '0')
        ].join(':');
    };

    return <span>{formatTime()}</span>;
};

export default CountdownTimer;