import { useEffect, useRef, useState } from "react";
import ChevronRight from "../icons/ChevronRight";
import "./accordion.css";

const Accordion = ({
    children,
    title,
    className = "",
    isDefaultOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState(isDefaultOpen);
    const [heightContent, setHeightContent] = useState(0);
    const contentWrapperRef = useRef(null);
    const contentRef = useRef(null);

    const toggleAccordion = () => {
        if (isOpen) {
            contentWrapperRef.current.style.overflow = "hidden";
        } else {
            setTimeout(() => {
                contentWrapperRef.current.style.overflow = "visible";
            }, 300);
        }
        setIsOpen(!isOpen);
    };


    useEffect(() => {
        if (contentRef.current) {
            const updateHeight = () => {
                setHeightContent(contentRef.current.clientHeight);
            };

            const resizeObserver = new ResizeObserver(updateHeight);
            resizeObserver.observe(contentRef.current);

            updateHeight();
            return () => resizeObserver.disconnect();
        }
    }, []);

    return (
        <div
            className={`accordion-item ${className} ${isOpen ? "active" : ""}`}
        >
            <div className="accordion-header" onClick={toggleAccordion}>
                <div className="accordion-icon">
                    <ChevronRight />
                </div>
                <span className="accordion-header-text">{title}</span>
            </div>
            <div
                className="accordion-content-wrapper"
                style={{ height: heightContent }}
                ref={contentWrapperRef}
            >
                <div ref={contentRef} className="accordion-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Accordion;
