import React, { Component } from 'react';
import axios from "axios";
import BasePage from "../base/BasePage";
import {UserGroup} from "../../context/User";
import {RestrictedArea} from "../../wrappers/RestrictedArea";
import {ImageType} from '../../common/commonTypes';

class TestPage extends BasePage {
    state = {
        selectedFile: null,
        uploadStatus: '',
        imageUrl: ''
    };

    handleFileChange(event) {
        this.setState({ selectedFile: event.target.files[0] });
    };

    async handleUpload() {
        if (!this.state.selectedFile) {
            this.setState({ uploadStatus: 'Please select the file.' });
            return;
        }

        try {
            const response = await axios.post('/image_upload',
                { image: this.state.selectedFile, image_type: ImageType.SCREENSHOT },
                { headers: {'Content-Type': 'multipart/form-data'} });
            this.setState({
                uploadStatus: 'Upload success!',
                imageUrl: `/images/${response.data.filename}`
            });
        } catch (e) {
            const err = (e.message ? e.message : JSON.stringify(e));
            this.setState({ uploadStatus: 'Upload failed. ' +  err});
            console.error('Error:', err);
        }
    };

    render() {
        const {loading} = this.state;
        return (
            <RestrictedArea allowedTo={UserGroup.DEVELOPER}>
            <div className="page">
                <h1>Test Page</h1>

                <div style={{ padding: '20px', maxWidth: '400px', margin: '0 auto' }}>
                    <h2>Image upload</h2>
                    <input type="file" onChange={this.handleFileChange.bind(this)} />
                    <button onClick={this.handleUpload.bind(this)} style={{ marginTop: '10px' }}>Upload</button>
                    <p>{this.state.uploadStatus}</p>
                    {this.state.imageUrl && (
                        <div style={{ marginTop: '20px', textAlign: 'center' }}>
                            <a className={'light'} href={this.state.imageUrl} target="_blank"
                               style={{fontSize: '20px'}} rel="noopener noreferrer">{this.state.imageUrl}</a>
                            <div style={{marginTop: '10px', padding: '10px', maxWidth: '200px', maxHeight: '200px',overflow: 'hidden'}}>
                                <img src={this.state.imageUrl} style={{ width: '100%',height: '100%',
                                    objectFit: 'contain' }} />
                            </div>
                        </div>
                    )}
                </div>

            </div>
            </RestrictedArea>
        );
    }
}

export default TestPage;

