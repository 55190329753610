import React, { Component } from 'react';
import axios from "axios";
import Link from "../../widgets/Link";
import queryString from "query-string";
import {useLocation} from "react-router-dom";

class OrganicsStats extends Component {
    state = {
        organic_id: 0,
        loading: true,
        stats: []
    };

    componentDidMount() {
        //let params = queryString.parse(this.props.location.search);
        //let a = 3;
        let params = queryString.parse(this.props.location.search);
        let organic_id = parseInt(params.id ? params.id : 0);
        if (organic_id !== 0) {
            //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
            this.fetchData(organic_id);
        }
    }

    async fetchData(organic_id) {
        this.setState({loading: true/*, organics: []*/});

        let res = await axios.post('/organic_stats', {organic_id: organic_id});

        if (res.data && Array.isArray(res.data.stats))
            this.setState({loading: false, organic_id: organic_id, stats: res.data.stats});
    }

    render() {
        //console.log(`[QQQ] {render} this.state.page: ${this.state.page}`,this.props.location.search);
        const {stats} = this.state;
        //console.log('[QQQ]',organics[0]);
        return (
            <div className="page organics organic-stats">
                <h1>Статистика потока органики</h1>

                <ul>
                    <li className="header" key={0}>
                        <div className="statsTargetLink">
                            Линк
                        </div>
                        <div className="statsCountries">
                            Гео
                        </div>
                        <div className="stats2">
                            Количество инсталлов
                        </div>
                    </li>
                    {stats.map((row,index) => (
                        <li key={index}>
                            <div className="statsTargetLink">
                                {row.targetLink}
                            </div>
                            <div className="statsCountries">
                                {row.country}
                            </div>
                            <div className="stats2">
                                {row.count}
                            </div>
                        </li>
                    ))}
                </ul>
                <div className="footer">
                    <div/>
                    <Link to="/organics"><button className="common text-and-icon secondary"><span className="icon"><i className="arrow left"/></span><span>Назад</span></button></Link>
                    <div/>
                </div>
            </div>
        );
    }
}

export default props => <OrganicsStats location={useLocation()} {...props}/>;

