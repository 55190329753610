import { useState } from "react";
import LanguagesTabs from "../../components/UI/LanguagesTabs";
import ModalWindow from "../../widgets/ModalWindow";
import AddLanguageForm from "./AddLanguageForm";

const LanguagesList = ({
    addLanguage,
    data,
    activeLng,
    setActiveLng,
    deleteLng,
    isShowModal,
    setIsShowModal,
    languagesList,
}) => {
    const showLanguagesModal = () => setIsShowModal(true);
    const closeLanguagesModal = () => setIsShowModal(false);

    return (
        <>
            <ModalWindow
                isVisible={isShowModal}
                onClose={closeLanguagesModal}
                anchorContainerID="create-edit-pwa"
                closeButton
            >
                {isShowModal && (
                    <AddLanguageForm
                        currentLanguage={data}
                        onSubmit={addLanguage}
                        onBack={closeLanguagesModal}
                        languagesList={languagesList}
                    />
                )}
            </ModalWindow>

            <LanguagesTabs
                addLanguage={showLanguagesModal}
                activeLng={activeLng}
                setActiveLng={setActiveLng}
                deleteLng={deleteLng}
                items={data}
                languagesList={languagesList}
            />
        </>
    );
};

export default LanguagesList;
