import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";

class GameStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            data: []
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/game_install_stats');

        if (res.data && Array.isArray(res.data))
            this.setState({loading: false, data: res.data});
    }

    render() {
        const { data } = this.state;

        let install_count = (count) => {
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        return (
            <div className="page">
                <h1>Статистика по приложениям</h1>

                <h2>Статистика инсталлов</h2>
                <div className='table-responsive perfect-scrollbar'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Приложение</th>
                                <th>За 24 часа</th>
                                <th>За сегодня</th>
                                <th>За вчера</th>
                                <th>За позавчера</th>
                                <th>Всего</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map(row => (
                                <tr key={row.name}>
                                    <td>{row.name}</td>
                                    <td>{install_count(row._24h_change)}</td>
                                    <td>{install_count(row.today_change)}</td>
                                    <td>{install_count(row.day_ago_change)}</td>
                                    <td>{install_count(row._2days_ago_change)}</td>
                                    <td>{row.total_installs}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default GameStats;
