import React, { Component } from 'react';
import axios from "axios";
import '../../css/table.css';
import {formatCurrency} from "../../util/util";
import {GameReleaseState} from "../../common/commonTypes";
import Link from "../../widgets/Link";
import {UserContext, UserGroup} from "../../context/User";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import Loader from '../../components/UI/Loader';

class AdminGameStats extends Component {
    // Initialize the state
    constructor(props){
        super(props);
        this.state = {
            platforms: [],
            data: {}
        }
    }

    // Fetch the list on first mount
    componentDidMount() {
        this.fetchData();
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/admin_game_install_stats');

        if (res.data)
            this.setState({loading: false, platforms: res.data.platforms, data: res.data});
    }

    render() {
        const { loading, platforms, data } = this.state;
        platforms.forEach( p => {
            p.tables = data ? [
                {name: 'По диплинкам', data: data.deeplinkInstalls.filter(g => g.platform_id === p.id)},
                {name: 'Органика', data: data.organicInstalls.filter(g => g.platform_id === p.id)}
            ] : [];
        });

        let install_count = (count) => {
            if (isNaN(count))
                return <div className="gray">0</div>;
            if (count >= 1000)
                return <div className="blue">{count}</div>
            if (count >= 300)
                return <div className="green">{count}</div>
            if (count > 0)
                return <div className="weakgreen">{count}</div>
            return <div className="gray">{count}</div>;
        };

        let game_name = (name,released) => {
            if (released === GameReleaseState.BANNED_ON_MARKET || released === GameReleaseState.NOT_RELEASED || released === GameReleaseState.WAIT_FOR_RELEASE)
                return <div className="red">{name}</div>;
            if (released === GameReleaseState.BANNED_BY_FB)
                return <div className="gray">{name}</div>;
            if (released === GameReleaseState.RESTRICTED_99_CABS)
                return <div className="yellow">{name}</div>;
            return name;
        };

        const showPlatformNames = this.getUser().hasPermission(UserGroup.MODERATOR);

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page">
                <h1>Статистика по приложениям</h1>

                {loading && <Loader/>}

                {!loading && platforms.map(platform =>
                    platform.tables.map((table, index) =>
                        <div key={index}>
                            <h2>{(showPlatformNames ? `Платформа ${platform.name}. ` : '') + table.name}</h2>
                            <div className="table-responsive perfect-scrollbar">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th>Приложение</th>
                                            <th>За 24 часа</th>
                                            <th>За сегодня</th>
                                            <th>За вчера</th>
                                            <th>За позавчера</th>
                                            <th>Всего</th>
                                            <th>Не доступные<br/>GEO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {table.data && table.data.map((row, index) => {
                                            const storeInfo = row.store_info ? JSON.parse(row.store_info) : {};
                                            const isPendingTestInstalls = row.released === GameReleaseState.NOT_RELEASED || row.total_installs > 0
                                            return <tr key={index}>
                                                <td>
                                                    <div className={row.released ? '' : 'gray'}><Link className="soft"
                                                                                                    to={`/admin/singlegamestats/?id=${row.id}`}>{game_name(row.name, row.released)}</Link>
                                                    </div>
                                                </td>
                                                <td className={(!row.released || row.total_installs > 0) ? 'smallerText' : 'verySmallText'}>
                                                        {isPendingTestInstalls ? install_count(row._24h_change) : <span className='nowrap'>Прилка готова и ждет <br/> тестовых инсталлов!</span>}
                                                </td>
                                                <td>{row.total_installs > 0 ? install_count(row.today_change) : ''}</td>
                                                <td>{row.total_installs > 0 ? install_count(row.day_ago_change) : ''}</td>
                                                <td>{row.total_installs > 0 ? install_count(row._2days_ago_change) : ''}
                                                </td>
                                                <td>{row.total_installs}
                                                </td>
                                                <td className=" strikethrough gray">{storeInfo.notAvailableGeo}
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                )}
            </div>
            </RestrictedArea>
        );
    }
}
AdminGameStats.contextType = UserContext;

export default AdminGameStats;
