import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import CloseIcon from '../../components/icons/CloseIcon'
import Button from '../../components/UI/Button'

const SLIDER_STEP_COUNT = 10000;
const MIN_STEP = 1 / SLIDER_STEP_COUNT;
const EPSILON = 1e-9;

const roundWeigth = w => Math.round(w * SLIDER_STEP_COUNT) / SLIDER_STEP_COUNT;
const clampWeight = w => {
    if (w < EPSILON) w = 0.0;
    if (w > 1.0 - EPSILON) w = 1.0;
    return w;
}

const AppTrafficDistribution = ({ appOptions, defaultDistribution, onChange }) => {
    const [distributions, setDistributions] = useState([]);

    useEffect(() => {
        if (distributions.length === 0) {
            const initialDistributions = defaultDistribution ? defaultDistribution.map((dist) => {
                const app = appOptions.find((app) => app.id === dist.app_id);
                if (app) {
                    return { app_id: app.id, weight: roundWeigth(dist.weight) };
                }
                return null;
            }).filter(Boolean) : [];
            setDistributions(initialDistributions);
        }
    }, [appOptions, defaultDistribution]);

    const handleAppChange = (selectedOption, index) => {
        const newDistributions = [...distributions];
        newDistributions[index].app_id = selectedOption.value.id;
        setDistributions(newDistributions);
    };

    const handleWeightChange = (value, index) => {
        let newDistributions = [...distributions];
        newDistributions[index].weight = clampWeight(roundWeigth(value));
        newDistributions = recalculateWeights(newDistributions, index);
        setDistributions(newDistributions);
    };

    const recalculateWeights = (distributions, changedIndex) => {
        const totalWeight = distributions.reduce((sum, dist) => sum + dist.weight, 0);

        if (totalWeight === 1) {
            return distributions;
        }

        if (distributions.length === 1 && changedIndex >= 0) {
            distributions[changedIndex].weight = 1;
            return distributions;
        }

        const remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
        const changeDirection = Math.sign(remainingWeight);
        const canChangeNextDists = distributions.reduce((_canChange, dist, i) => (i > changedIndex &&
            (_canChange || changeDirection < 0 && dist.weight > EPSILON || changeDirection > 0 && dist.weight < (1.0 - EPSILON))), false);

        let canChange;
        if (canChangeNextDists)
            // ми можемо автоматом крутити наступні повзунки після поточного, не збиваючи попередні значення
            canChange = (index) => index > changedIndex || (changedIndex === distributions.length-1) && index !== changedIndex;
        else
            // в цьому напрямку нема можливості крутити наступні, і доведеться автоматом просто крутити всі інші
            canChange = (index) => index !== changedIndex;

        const lockedWeights = distributions.filter((_, i) => !canChange(i));
        const desireRemainingWeight = lockedWeights.reduce((sum, dist) => sum - dist.weight, 1);
        const otherWeights = distributions.filter((_, i) => canChange(i));
        const otherWeightsSum = otherWeights.reduce((sum, dist) => sum + dist.weight, 0);

        const finalGrooming = (distributions) => {
            let remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
            if ( remainingWeight > -EPSILON && remainingWeight < EPSILON ) {
                return distributions;
            }
            const sign = Math.sign(remainingWeight);
            return distributions.map((dist, i) => {
                if (canChange(i) && ( sign > 0 && remainingWeight > (MIN_STEP - EPSILON)
                    || sign < 0 && remainingWeight < (-MIN_STEP + EPSILON) ))
                {
                    let newWeight = clampWeight(roundWeigth(dist.weight + sign * MIN_STEP));
                    remainingWeight = remainingWeight - (newWeight - dist.weight);
                    return {...dist, weight: newWeight};
                } else {
                    return dist;
                }
            });
        }

        if (otherWeightsSum === 0) {
            return finalGrooming(distributions.map((dist, i) =>
                canChange(i) ? { ...dist, weight: clampWeight(roundWeigth(desireRemainingWeight / otherWeights.length)) } : dist
            ));
        }

        return finalGrooming(distributions.map((dist, i) =>
            canChange(i) ? { ...dist, weight: clampWeight(roundWeigth((dist.weight / otherWeightsSum) * desireRemainingWeight)) } : dist
        ));
    };


    const handleAddApp = () => {
        setDistributions([...distributions, { app_id: null, weight: distributions.length === 0 ? 1 : 0 }]);
    };

    const handleRemoveApp = (index) => {
        const newDistributions = recalculateWeights(distributions.filter((_, i) => i !== index), -1);
        setDistributions(newDistributions);
    };

    useEffect(() => {
        onChange(
            distributions
                .filter((dist) => dist.app_id !== null)
                .map((dist) => ({
                    app_id: dist.app_id,
                    weight: dist.weight,
                }))
        );
    }, [distributions]);

    const selectedAppIds = distributions.map((dist) => dist.app_id);

    return (
        <div style={{margin: '0'}} className='traffic-distribution'>
            {/*<h3>Total : {distributions.reduce((sum, dist) => sum + dist.weight, 0)}</h3>*/}
            {distributions.map((dist, index) => (
                <div key={index} className={`traffic-distribution-item  ${distributions.length > 1 ? 'with-range' : ''}`}>
                    <div className='app-distribution-item-content'>
                        <Select className='select' style={{ width: '600px' }} value={dist.app_id ? { value: dist.app_id, label: appOptions.find(app => app.id === dist.app_id)?.name } : null}
                            onChange={(selectedOption) => handleAppChange(selectedOption, index)}
                            options={appOptions
                                .filter((app) => !selectedAppIds.includes(app.id) || app.id === dist.app_id)
                                .map((app) => ({ value: app, label: app.name }))}
                            placeholder="Выберите приложение"
                        />
                    
                    {distributions.length > 1 && <div className='range-block'>
                    <input
                        className='form-range'
                        type="range"
                        value={dist.weight * 1000}
                        onChange={(e) => handleWeightChange(Number(e.target.value) / 1000, index)}
                        min="0"
                        max={1000}
                        step={10}
                    />
                    <div style={{whiteSpace: "nowrap", margin: '6px 0'}}><input
                        type="number"
                        value={Math.round(dist.weight * 10000) / 100}
                        onChange={(e) => handleWeightChange(Number(e.target.value) / 100, index)}
                        min={0}
                        max={100}
                    />%</div>
                    <button
                        type="button"
                        onClick={() => handleRemoveApp(index)}
                        // className='delete-btn'
                    >
                        <CloseIcon/>
                    </button>
                    </div>}
                </div></div>
            ))}
            <Button title="+ Добавить распределение" variant='warning' withoutRounded size='small' type="button" style={{marginLeft: 10}} onClick={handleAddApp}>
                <span className="icon"><i className="plus"/></span>
            </Button>
        </div>
    );
};

export default AppTrafficDistribution;
