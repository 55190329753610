import React, { Component } from 'react';
import axios from "axios";
import {RestrictedArea}from "../../wrappers/RestrictedArea";
import {UserContext, UserGroup} from "../../context/User";
import SelectClient from "../../widgets/admin/SelectClient";
import {formatClientName, formatCurrency, formatDateTime, getResponseErrorsList} from "../../util/util";
import './admin.css';
import Select from "react-select";
import Pages from "../../widgets/Pages";
import BasePage from "../base/BasePage";
import Button from "../../components/UI/Button"

class DepositFunds extends BasePage {
    state = {
        loading: true,
        loadingClient: false,
        loadingHistory: true,
        selectedClientID: -1,
        depositHistoryPage: 1
    };

    componentDidMount() {
        this.fetchData();
        this.fetchHistory(this.state.depositHistoryPage);
    }

    getUser() {
        return this.context;
    }

    async fetchData() {
        this.setState({loading: true});

        let res = await axios.post('/admin_deposit_getdata', {data: ['clients','deposit_types']});

        if (res.data)
            this.setState({loading: false, clients: res.data.clients, depositTypes: res.data.deposit_types});
    }

    async fetchClientData(clientID) {
        if (clientID === -1) {
            this.setState({gamePerm: []});
            return;
        }

        this.setState({loadingClient: true});

        try {
            let res = await axios.post('/getclientdata', {client_id: clientID, data: ['name', 'contact_person', 'balance']});

            if (res.data)
                this.setState({loadingClient: false, clientData: res.data,
                    depositAmount: '', selectedDepositType: null, confirmed: false, confirmed2: false});
        } catch (err) {
            this.setState({loadingClient: false, errors: getResponseErrorsList(err)});
        }
    }

    async fetchHistory(page) {
        this.setState({loadingHistory: true});

        let res = await axios.post('/admin_get_deposit_history', {page: page});

        if (res.data && Array.isArray(res.data.depositHistory))
            this.setState({loadingHistory: false, depositHistoryPage: page, depositHistoryIsLast: res.data.isLast,
                depositHistory: res.data.depositHistory, walletBalance: res.data.walletBalance, transactionHistory: res.data.transactionHistory});
    }

    onClientSelected(clientID) {
        this.setState({ errors: {}, selectedClientID: clientID });
        this.fetchClientData(clientID);
    }

    onDepositTypeSelected(depositType) {
        this.setState({ errors: {}, selectedDepositType: depositType });
    }

    async onSubmit(event) {
        event.preventDefault();

        let depositAmount = parseFloat(this.state.depositAmount);
        if (depositAmount <= 0) {
            this.setState({errors: {depositAmount: 'Укажите сумму'}, confirmed: false, confirmed2: false});
            return;
        }
        if (!this.state.selectedDepositType || !this.state.selectedDepositType.value) {
            this.setState({errors: {depositType: 'Укажите счет'}, confirmed: false, confirmed2: false});
            return;
        }
        depositAmount = depositAmount.toFixed(2);

        if (!this.state.confirmed) {
            this.setState({confirmed: true, depositAmount: depositAmount});
        } else if (this.state.confirmed && this.state.confirmed2 && !this.busy) {
            try {
                this.busy = true;
                await axios.post('/admin_make_deposit', {
                    client_id: this.state.selectedClientID,
                    deposit_amount: this.state.depositAmount,
                    deposit_type_id: this.state.selectedDepositType.value,
                });

                this.setState({errors: {}, depositAmount: '', selectedDepositType: null, confirmed: false, confirmed2: false});
                this.fetchClientData(this.state.selectedClientID);
                this.fetchHistory(1);
            } catch (err) {
                this.setState({errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }

    }

    onBack() {
        this.setState({confirmed: false, confirmed2: false});
    }

    renderPage() {
        const { confirmed, confirmed2, depositAmount, loading,
            loadingClient, clients, clientData, selectedClientID, depositTypes, selectedDepositType,
            loadingHistory, depositHistory, depositHistoryPage, depositHistoryIsLast, transactionHistory, walletBalance} = this.state;

        const inputField = (fieldName,description) => (
            <li className="field">
                <div><label htmlFor={fieldName}>{description}</label></div>
                <div><input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)} required value={this.state[fieldName]}/>
                    {this.renderError(fieldName)}
                </div>
            </li>
        );

        const roField = (description, value, valueStyle) => (
            <li className="field">
                <div>{description}</div>
                <div style={valueStyle ? valueStyle : {}}>{value}</div>
            </li>
        );

        const onConfirmed2 = () => { this.setState({confirmed2: !confirmed2}) };

        let fundOptions = depositTypes ? depositTypes.map(dt => ({value: dt.id, label: dt.name })) : [];

        const isAdmin = this.getUser().hasPermission(UserGroup.ADMIN);

        /*{ errors &&
        <div className="error holder"><div className="message">{errors}</div></div>
        }*/

        return (
            <RestrictedArea allowedTo={UserGroup.PLATFORM_ADMIN}>
            <div className="page deposit-funds-admin">
                <h1>Пополнение счета</h1>

                <div>
                    <div className='center-section' style={{marginTop: 16}}>
                        <SelectClient
                            clients={clients}
                            onChange={this.onClientSelected.bind(this)}
                            isLoading={loading}
                            isDisabled={loadingClient}
                        />
                    </div>

                    { !loading && selectedClientID !== -1 && clientData &&
                    <div className="card center-section" style={{maxWidth: 480, marginTop: 16}}>
                        <form onSubmit={this.onSubmit.bind(this)} className={loadingClient ? 'disabled' : ''}>
                                <h2>{formatClientName(clientData)}: </h2>
                                <ul>
                                    {roField('Текущий баланс:', formatCurrency(clientData.balance), {fontWeight:'bold', fontSize: 13})}

                                    {!confirmed ?
                                    inputField("depositAmount","Пополнить на, $:")
                                    :
                                    roField("Пополняем счет на:", '+' + formatCurrency(depositAmount), {fontWeight: 'bold', color: '#318d31'})
                                    }

                                    {!confirmed &&
                                    <li className={'field'}><div>Средства поступили на:</div>
                                        <div><Select
                                            className="select"
                                            onChange={this.onDepositTypeSelected.bind(this)}
                                            options={fundOptions}
                                            isDisabled={loadingClient}
                                            value={selectedDepositType}
                                            placeholder={'-- Счет --'}
                                        />
                                        {this.renderError('depositType')}
                                        </div>
                                    </li>
                                    }

                                    {confirmed &&
                                    <li className={'checkbox'}  onClick={onConfirmed2}>
                                        <div><input type="checkbox"
                                            checked={confirmed2}
                                                        disabled={loadingClient}/></div>
                                        <div>Я проверил и подтверждаю что пользователь уже перевел средства на счет <b>{ selectedDepositType.label }</b></div>
                                    </li>
                                    }

                                    <li className="submit">
                                        <div>
                                            {confirmed &&
                                            <div>
                                                <Button 
                                                variant="warning"
                                                withoutRounded
                                                title={<><span style={{transform: 'rotate(180deg)', marginRight: 4}}>➡</span>Назад</>} 
                                                isDisabled={loadingClient} 
                                                onClick={this.onBack.bind(this)}/>
                                                </div>
                                            }
                                        </div>
                                        <div>
                                            <div>
                                                <Button 
                                                type="submit"
                                                withoutRounded 
                                                title={confirmed ? '✔ Пополнить' : 'Пополнить ➡'}
                                                isDisabled={loadingClient || (confirmed && !confirmed2)}
                                                />
                                            </div>
                                            {this.renderRestErrors()}
                                        </div>
                                    </li>
                                </ul>
                        </form>
                    </div>
                    }

                </div>

                <div style={{marginTop: '30px'}}>
                    {isAdmin ? <h2>История пополнений</h2> : <h2>Недавние пополнения</h2>}
                    <div className="table-responsive perfect-scrollbar">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>Время</th>
                                    <th>Клиент</th>
                                    <th>Сумма</th>
                                    <th>Счет</th>
                                    <th>Платформа</th>
                                    <th>Админ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {depositHistory && depositHistory.map((hist,i) => (
                                    <tr key={i}>
                                        <td>{formatDateTime(hist.time)}</td>
                                        <td>{formatClientName(hist)}</td>
                                        <td>{formatCurrency(hist.amount)}</td>
                                        <td>{hist.depositType}</td>
                                        <td>{hist.platform}</td>
                                        <td>{hist.admin}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="footer">
                        <Pages style={{margin: '0 auto', width: 'auto'}} handler={this.fetchHistory.bind(this)} enabled={!loadingHistory} current={depositHistoryPage} isLast={depositHistoryIsLast}/>
                    </div>
                </div>

                {isAdmin && <div>
                    <h2>
                        Баланс кошелька: <span className={'green'}><b>{walletBalance}</b> USDT</span>
                    </h2>
                    <div className="table-responsive perfect-scrollbar">
                        <table className="table" style={{width: 'auto', margin: '0 auto'}}>
                            <thead>
                                <tr>
                                    <th>Время</th>
                                    <th>Сумма</th>
                                    <th>Transaction ID</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionHistory && transactionHistory.map((tr,i) => (
                                    <tr key={i}>
                                        <td>{formatDateTime(tr.time)}</td>
                                        <td className={tr.value > 0 ? 'green' : 'red'}>
                                            {tr.value > 0 ? '+' : ''}{tr.value}</td>
                                        <td>{tr.transaction_id}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>}

            </div>
            </RestrictedArea>
        );
    }
}
DepositFunds.contextType = UserContext;


export default DepositFunds;
